import { useCallback, useEffect } from 'react';

import useMartyContext from 'hooks/useMartyContext';
import Carousel from 'components/common/Carousel/Carousel';
import MelodyCardProduct from 'components/common/melodyCard/MelodyCardProduct';
import marketplace from 'cfg/marketplace.json';
import { evSearchProductStreamClick, evSearchProductStreamImpression } from 'events/symphony';
import { track } from 'apis/amethyst';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/landing/brandTrending.scss';

const {
  search: { msaImageParams }
} = marketplace;

export const BrandTrending = ({ slotDetails = {}, slotName, slotIndex, onTaxonomyComponentClick, slotHeartsData, slideWidths }) => {
  const { trending } = slotDetails;
  const { results, filters: { brandNameFacet } = {} } = trending || {};
  const { testId } = useMartyContext();

  useEffect(() => {
    if (results) {
      track(() => [
        evSearchProductStreamImpression,
        {
          slotDetails,
          slotIndex,
          slotName,
          displayedItemsDirty: results
        }
      ]);
    }
  }, [slotDetails, results, slotIndex, slotName]);

  const onClick = useCallback(
    (evt, product = {}) => {
      onTaxonomyComponentClick && onTaxonomyComponentClick(evt);
      track(() => [
        evSearchProductStreamClick,
        {
          slotDetails,
          slotIndex,
          slotName,
          product
        }
      ]);
    },
    [onTaxonomyComponentClick, slotDetails, slotIndex, slotName]
  );

  if (results) {
    return (
      <div className={css.brandTrendingWrap} data-test-id={testId('brandTrendingContainer')}>
        <Carousel
          header={{ title: `${brandNameFacet[0]}: Selected For You` }}
          slideWidths={slideWidths}
          slides={results.map((product, index) => (
            <MelodyCardProduct
              key={product.styleId}
              index={index}
              cardData={product}
              msaImageParams={msaImageParams}
              shouldLazyLoad={true}
              eventLabel="Trending Product"
              onComponentClick={onClick}
              componentStyle="brandTrendingStyle"
              melodyCardTestId="trendingProduct"
              heartsData={slotHeartsData}
            />
          ))}
        />
      </div>
    );
  }
  return null;
};

export default withErrorBoundary('BrandTrending', BrandTrending);
